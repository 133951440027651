import { memo, useCallback } from "react"

import { SpacesVideoHighlightsFeedComponentData } from "@spatialsys/js/sapi/types"
import { useTrackInteraction } from "@spatialsys/react/analytics"
import { RoomJoinMethod } from "@spatialsys/unity/app-state"
import { SpaceJoinContextSource, createSpaceClickEvent, createSpaceMetadataProperties } from "@spatialsys/unity/bridge"
import { useAppContext } from "@spatialsys/web/app-context"
import { cn } from "@spatialsys/web/ui"

import { useIsMobile } from "../../../hooks/use-is-mobile"
import { useVideoPlayingContext } from "../../video-playing-context"
import { SectionHeader } from "../section-header/section-header"
import { SpaceHighlightVideo, SpaceHighlightVideoProps } from "../space-highlight-video/space-highlight-video"

import classes from "./spaces-highlight-videos.module.scss"

type SpacesHighlightVideosProps = SpacesVideoHighlightsFeedComponentData & { sectionIndex: number }

const noop = () => {}

export const SpacesHighlightVideos = memo(function SpacesHighlightVideos(props: SpacesHighlightVideosProps) {
  const { analyticsContext, title, sectionIndex, spaces } = props
  const trackInteraction = useTrackInteraction()
  const actions = useAppContext((context) => context.actions)
  const isMobile = useIsMobile()
  const { activeVideo, setActiveVideo, clearActiveVideo } = useVideoPlayingContext()

  const handleSelectSpace = useCallback<SpaceHighlightVideoProps["onSelectSpace"]>(
    (space, index) => {
      const spaceProperties = createSpaceMetadataProperties(space)
      actions.setSpaceJoinContext({
        method: RoomJoinMethod.UserSelected,
        spaceProperties,
        discoveryMetadata: {
          "On Video Thumbnail": true,
          "Section Index": sectionIndex,
          "Space Index": index,
          Source: SpaceJoinContextSource.HomeFeed,
          ...analyticsContext,
        },
      })
      trackInteraction(
        ...createSpaceClickEvent({
          ...spaceProperties,
          "On Video Thumbnail": true,
          "Section Index": sectionIndex,
          "Space Index": index,
          Source: SpaceJoinContextSource.HomeFeed,
          ...analyticsContext,
        })
      )
    },
    [actions, analyticsContext, sectionIndex, trackInteraction]
  )

  const onPointerOver = useCallback(
    (index: number) => {
      setActiveVideo(index, sectionIndex)
    },
    [sectionIndex, setActiveVideo]
  )

  return (
    <div>
      <SectionHeader title={title} />
      <div className={cn(classes.row, "gap-2 mobile:gap-4 md:gap-5")}>
        {spaces.map((space, i) => {
          const isPlaying = activeVideo?.[0] === i && activeVideo?.[1] === sectionIndex
          return (
            <SpaceHighlightVideo
              key={i}
              index={i}
              isPlaying={isPlaying}
              space={space}
              onSelectSpace={handleSelectSpace}
              onPointerOver={onPointerOver}
              onPointerLeave={isMobile ? noop : clearActiveVideo}
            />
          )
        })}
      </div>
    </div>
  )
})
