import Image from "next/image"
import Link from "next/link"
import { memo, useCallback, useEffect, useRef } from "react"
import ReactPlayer from "react-player"
import { CSSTransition } from "react-transition-group"

import { SpaceAndCreator, SpaceMetadata } from "@spatialsys/js/sapi/types"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { screensPx } from "@spatialsys/theme/screens"
import { formatSpacePath } from "@spatialsys/url-utils"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { Modals } from "@spatialsys/web/app-state"
import classes from "@spatialsys/web/core/css/components/space-poster-preview.module.scss"
import { LoveSpaceButton } from "@spatialsys/web/core/js/components/love-space-button/love-space-button"
import { Text, cn } from "@spatialsys/web/ui"

import { LoveCount } from "../../social-signals/love-count"
import { ViewCount } from "../../social-signals/view-count"
import { LiveBadge } from "../../space-badges/live-badge"

type PreviewMediaType = "video" | "image"

export type SpaceHighlightVideoProps = {
  space: SpaceAndCreator
  index: number
  isPlaying: boolean
  onPointerLeave: () => void
  onPointerOver: (i: number) => void
  onSelectSpace: (space: SpaceMetadata, index: number) => void
  containerClassName?: React.StyleHTMLAttributes<HTMLDivElement> | string
  isPreview?: boolean
  previewMediaType?: PreviewMediaType
}

export const SpaceHighlightVideo = memo(function SpaceHighlightVideo(props: SpaceHighlightVideoProps) {
  const {
    space,
    index,
    isPlaying,
    onPointerLeave,
    onPointerOver,
    onSelectSpace,
    containerClassName,
    isPreview,
    previewMediaType,
  } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isVideoLoaded, setVideoLoaded] = useBoolean(false)
  const actions = useAppContext((context) => context.actions)
  const { isAuthenticatingOrLoggingIn } = useAuthState()

  const openLoginModal = useCallback(
    () => actions.openModal({ type: Modals.Login, payload: { titleCta: "Sign in to continue" } }),
    [actions]
  )

  const handlePointerOver = useCallback(() => {
    onPointerOver(index)
  }, [index, onPointerOver])

  useEffect(() => {
    if (!isPlaying && isVideoLoaded) {
      setVideoLoaded.setFalse()
    }
  }, [isVideoLoaded, isPlaying, setVideoLoaded])

  useEffect(() => {
    if (!isPlaying) {
      videoRef.current?.pause()
    } else {
      void videoRef.current?.play().catch(() => {})
    }
  }, [isPlaying])

  const href = formatSpacePath({ id: space.space.id, slug: space.space.slug, shareId: space.space.shareID })

  return (
    <div
      className={cn(
        // `isolate` needed for Safari https://stackoverflow.com/a/64885552/1546294
        "shadow group/link relative isolate aspect-[3/5] h-full max-h-[700px] w-full min-w-[148px] max-w-[420px] overflow-hidden rounded-lg text-white",
        containerClassName
      )}
      onPointerOver={handlePointerOver}
      onPointerLeave={onPointerLeave}
    >
      {(() => {
        if (isPreview) {
          if (previewMediaType === "video" && space.space.posterVideo?.mp4) {
            return (
              <div className={cn("absolute inset-0 object-cover", classes.videoPreviewContainer)}>
                {/* We use ReactPlayer to easily show/play a blob video which would be from the file upload. 
                It is only used for media preview, the preview playback after file uploading. */}
                <ReactPlayer
                  url={space.space.posterVideo.mp4}
                  playing
                  loop
                  stopOnUnmount
                  playsinline
                  width="100%"
                  height="100%"
                />
              </div>
            )
          }
          if (
            previewMediaType === "image" &&
            (space.space.posterVideo?.poster || space.space.posterVideo?.posterVideoFirstFrame)
          ) {
            return (
              <div>
                <Image
                  src={space.space.posterVideo.poster || space.space.posterVideo.posterVideoFirstFrame}
                  fill
                  sizes={`(min-width: ${screensPx["4xl"]}px) 420px, (min-width: ${screensPx.xs}px) 20vw, 148px`}
                  alt={`${space.space.name} poster`}
                  className="object-cover"
                  loading="lazy"
                />
                <div className="relative flex items-end">
                  {space.space.logo && (
                    <Image
                      src={space.space.logo}
                      fill
                      sizes={`(min-width: ${screensPx["4xl"]}px) 420px, (min-width: ${screensPx.lg}px) 270px, 148px`}
                      alt={`${space.space.name} logo`}
                      className="!top-auto bottom-0 mb-1.5 !h-auto w-full object-contain px-1.5"
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
            )
          }
          return null
        }
        return (
          <>
            <CSSTransition
              in={isPlaying}
              nodeRef={videoRef}
              mountOnEnter
              unmountOnExit
              classNames={{ exitActive: "opacity-0" }}
              timeout={150} // match with transition below
            >
              <video
                ref={videoRef}
                onEnded={() => {
                  if (isPlaying) {
                    void videoRef.current?.play().catch(() => {})
                  }
                }}
                className={cn(
                  // duration matching the poster image, matching the transition duration
                  "absolute inset-0 h-full w-full object-cover transition-opacity duration-150",
                  isPlaying && isVideoLoaded ? "opacity-100" : "opacity-0"
                )}
                onCanPlay={setVideoLoaded.setTrue}
                playsInline
                muted
              >
                {space.space.posterVideo?.webm && <source type="video/webm" src={space.space.posterVideo.webm} />}
                {space.space.posterVideo?.mp4 && <source type="video/mp4" src={space.space.posterVideo?.mp4} />}
              </video>
            </CSSTransition>
            <div
              className={cn(
                "absolute h-full w-full transition-opacity duration-150",
                // While video is loading, darken the image to show some feedback
                isPlaying && (isVideoLoaded ? "opacity-0" : "opacity-70")
              )}
            >
              <Image
                src={space.space.posterVideo!.poster}
                fill
                sizes={`(min-width: ${screensPx["4xl"]}px) 420px, (min-width: ${screensPx.xs}px) 20vw, 148px`}
                alt={`${space.space.name} poster`}
                className="object-cover"
                loading="lazy"
              />
            </div>
          </>
        )
      })()}
      <div className="absolute left-2 top-2 md:left-3 md:top-3">
        <LiveBadge activeUserCount={space.space.activeUserCount} isLive={space.space.live} />
      </div>
      <LoveSpaceButton
        classNameIcon="icon-md mobile:icon-lg drop-shadow group-hover/link:block hidden"
        classNameContainer="absolute right-3 top-3 z-10"
        handleUnauthenticatedClick={openLoginModal}
        loveCount={space.space.likeCount}
        isLoved={space.space.liked}
        spaceId={space.space.id}
        isDisabled={isAuthenticatingOrLoggingIn}
        hideLoveCount
      />

      <div className="absolute bottom-0 flex w-full flex-col">
        <div className="relative flex items-end">
          {space.space.logo && !isPreview && (
            <Image
              src={space.space.logo}
              fill
              sizes={`(min-width: ${screensPx["4xl"]}px) 420px, (min-width: ${screensPx.lg}px) 270px, 148px`}
              alt={`${space.space.name} logo`}
              className="!top-auto bottom-0 mb-1.5 !h-auto w-full object-contain px-1.5"
              loading="lazy"
            />
          )}
        </div>
        <div className="hidden bg-gradient-to-b from-transparent via-black/30 via-25% to-black/70 px-3 pb-1.5 md:block">
          <Link
            href={`/@${space.creator.username}`}
            className="pointer-events-auto line-clamp-1 min-w-0 no-underline hover:underline"
            tabIndex={isPreview ? -1 : undefined}
          >
            <Text className="line-clamp-1 font-heading text-sm font-demibold text-white drop-shadow">
              {space.creator.displayName}
            </Text>
          </Link>
          <div className="whitespace-nowrap font-heading text-xs font-demibold text-white">
            <ViewCount numViews={space.space.joinCount} />
            <span className="px-[3px]">•</span>
            <LoveCount numLoves={space.space.likeCount} />
          </div>
        </div>
      </div>
      <Link href={href} className="absolute inset-0" onClick={() => onSelectSpace(space.space, index)} />
    </div>
  )
})
